<template>
  <div class="layer-page-div">
    <div class="item-content-div">
      <div class="items-div">
        <div class="item-div">
          <div class="role-group-div">
            <label class="item-name-label">上级角色：</label>
            <my-select class="mySelect" :option-data="[{id:0, name:'默认角色'}]" :load-more="true" :action="action" :change="onSelectChanged" placeholder-txt="请选择角色"/>
          </div>
        </div>
        <div class="item-div">
          <label class="item-name-label">角色名称：</label>
          <label>
            <input type="text" v-model="groupName" placeholder="请输入角色名称" />
          </label>
        </div>
      </div>
      <div class="authority-div">
        <label>角色权限</label>
      </div>
      <div class="authority-tree-div">
        <tree :data="authority" :show-checkbox="true"/>
      </div>
      <div class="submit-div">
        <div class="tips-div text-c err-tips">{{ tipMsg }}</div>
        <input type="button" value="确  定" @click="onSubmit" />
      </div>
    </div>
  </div>
</template>

<script>
import MySelect from "../../components/MySelect"
import tree from "../../components/tree"
import { getGroupNameItems, getGroupAuthority, postAddGroup } from "../../common/api";

export default {
  components: { MySelect, tree },
  props: {
    layerid: {
      type: String,
      default: ""
    },
    refreshData: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      action: getGroupNameItems,
      groupId: 0,
      groupName: "",
      authority: [],
      tipMsg: "",
    }
  },
  created() {
    this.onSelectChanged({id: 0})
  },
  methods: {
    onSelectChanged(item) {
      this.groupId = item.id;
      getGroupAuthority({groupId: this.groupId})
        .then(data => {
          this.authority = [];
          if(data && data instanceof Array){
            data.forEach(value => {
              let node = {
                id: value.id,
                title: value.name,
                children: []
              }
              this.authority.push(node);

              if(Object.prototype.hasOwnProperty.call(value, "children")){
                this.initChildAuthorityTree(value.children, node)
              }
            });
          }
        })
        .catch(error => {
          if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
            this.$layer.msg(error.data.msg);
          } else {
            this.$layer.msg("添加管理角色失败");
          }
        });
    },
    initChildAuthorityTree(authority, parentNode) {
      authority.forEach(value => {
        let node = {
          id: value.id,
          title: value.name,
          children: []
        }
        parentNode.children.push(node);

        if(Object.prototype.hasOwnProperty.call(value, "children")){
          this.initChildAuthorityTree(value.children, node);
        }
      });
    },
    onSubmit() {
      if(this.groupName.length < 2 || this.groupName.length > 10){
        this.setTipsMsg("角色名称由2~10个字符组成");
        return;
      }

      let authorityIds = [];
      this.authority.forEach(value => {
        if(value.checked){
          authorityIds.push(value.id);

          if(Object.prototype.hasOwnProperty.call(value, "children")){
            this.getSelectedAuthorityId(authorityIds, value.children);
          }
        }
      });

      let formData = new FormData();
      formData.append("name", this.groupName);
      if(this.groupId > 0){
        formData.append("parentId", this.groupId);
      }
      authorityIds.forEach(value => {
        formData.append("authority", value)
      });

      postAddGroup(formData)
        .then(() => {
          this.refreshData();
          this.$layer.close(this.layerid);
          this.$layer.msg("添加管理角色成功");
        })
        .catch(error => {
          if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
            this.$layer.msg(error.data.msg);
          } else {
            this.$layer.msg("添加管理角色失败");
          }
        });
    },
    getSelectedAuthorityId(authorityIds, authority) {
      authority.forEach(value => {
        if(value.checked){
          authorityIds.push(value.id);

          if(Object.prototype.hasOwnProperty.call(value, "children")){
            this.getSelectedAuthorityId(authorityIds, value.children)
          }
        }
      });
    },
    setTipsMsg(msg) {
      this.tipMsg = msg;
      clearTimeout(this.timeCounter);
      this.timeCounter = setTimeout(() => {
        this.tipMsg = "";
      }, 3000);
    }
  }
}
</script>

<style lang="less" scoped>
.layer-page-div {
  width: 100%;
  font-size: 14px;
  color: #2c3e50;
  .item-content-div {
    margin-top: 10px;
    .items-div {
      display: flex;
      justify-content: space-around;
      .item-div {
        .item-name-label {
          margin-right: 10px;
        }
        input {
          width: 220px;
          height: 33px;
          padding: 0 5px;
          border: 1px solid #dcdcdc;
        }
        .role-group-div {
          display: inline-flex;
          align-items: center;
          .mySelect {
            width: 232px;
            height: 33px;
          }
        }
      }
    }
    .authority-div {
      width: 90%;
      margin: 20px auto 0 auto;
    }
    .authority-tree-div {
      width: 90%;
      height: 360px;
      margin: 5px auto;
      border: 1px solid #dcdcdc;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
  .submit-div {
    text-align: center;
    margin-top: 15px;
    input {
      width: 120px;
      height: 35px;
      color: white;
      font-size: 16px;
      border: none;
      background: #4676c8;
      margin-top: 10px;
    }
    .tips-div {
      width: 100%;
      height: 25px;
    }
  }
}
</style>