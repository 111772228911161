<template>
  <div class="layer-page-div">
    <div class="item-content-div">
      <div class="items-div">
        <div class="item-div">
          <label class="item-name-label">角色名称：</label>
          <label>
            <input type="text" v-model="groupInfo.name" placeholder="请输入角色名称" />
          </label>
        </div>
      </div>
      <div class="authority-div">
        <label>角色权限</label>
      </div>
      <div class="authority-tree-div">
        <tree :data="authority" :show-checkbox="true"/>
      </div>
      <div class="submit-div">
        <div class="tips-div text-c err-tips">{{ tipMsg }}</div>
        <input type="button" value="确  定" @click="onSubmit" />
      </div>
    </div>
  </div>
</template>

<script>
import tree from "../../components/tree"
import { getGroupAuthorityState, postEditGroup } from "../../common/api";

export default {
  components: { tree },
  props: {
    layerid: {
      type: String,
      default: ""
    },
    groupInfo: {
      type: Object,
      default: {}
    },
    refreshData: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      authority: [],
      tipMsg: "",
    }
  },
  created() {
    this.getGroupAuthority();
  },
  methods: {
    getGroupAuthority() {
      getGroupAuthorityState({groupId: this.groupInfo.id})
        .then(data => {
          if(data && data instanceof Array){
            data.forEach(value => {
              let node = {
                id: value.id,
                title: value.name,
                checked: value.state > 0,
                children: []
              }
              this.authority.push(node);

              if(Object.prototype.hasOwnProperty.call(value, "children")){
                this.initChildAuthorityTree(value.children, node)
              }
            });
          }
        })
        .catch(error => {
          if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
            this.$layer.msg(error.data.msg);
          } else {
            this.$layer.msg("查询管理角色权限失败");
          }
          this.$layer.close(this.layerid);
        });
    },
    initChildAuthorityTree(authority, parentNode) {
      authority.forEach(value => {
        let node = {
          id: value.id,
          title: value.name,
          checked: value.state > 0,
          children: []
        }
        parentNode.children.push(node);

        if(Object.prototype.hasOwnProperty.call(value, "children")){
          this.initChildAuthorityTree(value.children, node);
        }
      });
    },
    onSubmit() {
      if(this.groupInfo.name.length < 2 || this.groupInfo.name.length > 10){
        this.setTipsMsg("角色名称由2~10个字符组成");
        return;
      }

      let authorityIds = [];
      this.authority.forEach(value => {
        if(value.checked){
          authorityIds.push(value.id);

          if(Object.prototype.hasOwnProperty.call(value, "children")){
            this.getSelectedAuthorityId(authorityIds, value.children);
          }
        }
      });

      let formData = new FormData();
      formData.append("id", this.groupInfo.id);
      formData.append("name", this.groupInfo.name);
      authorityIds.forEach(value => {
        formData.append("authority", value)
      });

      postEditGroup(formData)
        .then(() => {
          this.refreshData();
          this.$layer.close(this.layerid);
          this.$layer.msg("编辑管理角色成功");
        })
        .catch(error => {
          if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
            this.$layer.msg(error.data.msg);
          } else {
            this.$layer.msg("编辑管理角色失败");
          }
        });
    },
    getSelectedAuthorityId(authorityIds, authority) {
      authority.forEach(value => {
        if(value.checked){
          authorityIds.push(value.id);

          if(Object.prototype.hasOwnProperty.call(value, "children")){
            this.getSelectedAuthorityId(authorityIds, value.children)
          }
        }
      });
    },
    setTipsMsg(msg) {
      this.tipMsg = msg;
      clearTimeout(this.timeCounter);
      this.timeCounter = setTimeout(() => {
        this.tipMsg = "";
      }, 3000);
    }
  }
}
</script>

<style lang="less" scoped>
.layer-page-div {
  width: 100%;
  font-size: 14px;
  color: #2c3e50;
  .item-content-div {
    margin-top: 10px;
    .items-div {
      text-align: center;
      .item-div {
        .item-name-label {
          margin-right: 10px;
        }
        input {
          width: 220px;
          height: 33px;
          padding: 0 5px;
          border: 1px solid #dcdcdc;
        }
      }
    }
    .authority-div {
      width: 90%;
      margin: 20px auto 0 auto;
    }
    .authority-tree-div {
      width: 90%;
      height: 360px;
      margin: 5px auto;
      border: 1px solid #dcdcdc;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
  .submit-div {
    text-align: center;
    margin-top: 15px;
    input {
      width: 120px;
      height: 35px;
      color: white;
      font-size: 16px;
      border: none;
      background: #4676c8;
      margin-top: 10px;
    }
    .tips-div {
      width: 100%;
      height: 25px;
    }
  }
}
</style>