<template>
  <input ref="checkbox" type="checkbox" :disabled="disabled" :checked="checked" @change="onChange" />
</template>
<script>
export default {
  name: "Checkbox",
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    checked: {
      type: Boolean,
      default: false
    },
    indeterminate: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.$refs.checkbox.indeterminate = this.indeterminate;
  },
  methods: {
    onChange() {
      this.$emit("change");
    }
  },
  watch: {
    indeterminate(val) {
      this.$refs.checkbox.indeterminate = val;
    }
  }
};
</script>
