<template>
  <ul>
    <li>
      <div class="node-div">
        <img :src="require(data.expand ? '../../assets/arrow-down.png' : '../../assets/arrow-right.png')" alt="" @click="onHandleExpand" />
        <checkbox v-if="showCheckbox" class="checkbox" :checked="data.checked" :indeterminate="data.selectedCount > 0 && data.selectedCount < data.nodeNum" @change="onHandleChecked" />
        <label>{{ data.title }}</label>
      </div>
      <template v-if="data.expand">
        <node v-for="(item, i) in children" :ref="`node${item.nodeKey}`" :key="i" :data="item" :show-checkbox="showCheckbox" />
      </template>
    </li>
  </ul>
</template>

<script>
import Checkbox from "./Checkbox";
import Emitter from "../../common/emitter";

export default {
  name: "Node",
  mixins: [Emitter],
  components: { Checkbox },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      }
    },
    showCheckbox: {
      type: Boolean,
      default: false
    },
    childrenKey: {
      type: String,
      default: "children"
    }
  },
  computed: {
    children() {
      return this.data[this.childrenKey];
    }
  },
  methods: {
    onHandleExpand() {
      if (Object.prototype.hasOwnProperty.call(this.data, this.childrenKey) && this.children.length > 0) {
        this.$set(this.data, "expand", !this.data.expand);
      }
    },
    onHandleChecked() {
      let status = !this.data.checked;
      this.$set(this.data, "checked", status);
      this.setChildrenChecked(this.data, status);
      this.dispatch("Tree", "on-check", this.data);
    },
    setChildrenChecked(data, status) {
      this.getChildren(data).forEach(v => {
        this.$set(v, "checked", status);
        this.setChildrenChecked(v, status);
      });
    },
    getChildren(data) {
      if (Object.prototype.hasOwnProperty.call(data, this.childrenKey)) {
        return data[this.childrenKey];
      } else {
        return [];
      }
    }
  }
};
</script>

<style lang="less" scoped>
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  li {
    list-style: none;
    padding: 0;
    white-space: nowrap;
    outline: none;
  }
}
li {
  ul {
    margin: 0;
    padding: 0 0 0 18px;
  }
}
.node-div {
  height: 25px;
  display: flex;
  align-items: center;
  img {
    width: 13px;
    height: 13px;
    margin-right: 10px;
  }
  .checkbox {
    margin-right: 10px;
  }
}
</style>
