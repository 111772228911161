<template>
  <div class="tree-content-div">
    <tree-node v-for="(item, i) in stateTree" :key="i" :data="item" :show-checkbox="showCheckbox" />
  </div>
</template>

<script>
import TreeNode from "./Node.vue";

export default {
  name: "Tree",
  components: { TreeNode },
  props: {
    data: {
      type: Array,
      default() {
        return [];
      }
    },
    showCheckbox: {
      type: Boolean,
      default: false
    },
    childrenKey: {
      type: String,
      default: "children"
    }
  },
  data() {
    return {
      stateTree: this.data
    };
  },
  watch: {
    data(value) {
      this.stateTree = value;
      this.initStateTree();
    }
  },
  methods: {
    initStateTree() {
      let nodeKey = 0;
      this.stateTree.forEach(v => {
        ++nodeKey;
        v.parentKey = 0;
        v.nodeKey = nodeKey;
        let setResult = this.setChildrenKey(v, v.nodeKey);
        v.nodeNum = setResult[0] - v.nodeKey;
        this.$set(v, "selectedCount", setResult[1]);
        nodeKey = setResult[0];
      });
    },
    setChildrenKey(data, nodeKey) {
      let parentKey = nodeKey;
      let selectedCount = 0;
      this.getChildren(data).forEach(v => {
        ++nodeKey;
        if (v.checked) {
          ++selectedCount;
        }
        v.parentKey = parentKey;
        v.nodeKey = nodeKey;
        let setResult = this.setChildrenKey(v, v.nodeKey);
        v.nodeNum = setResult[0] - v.nodeKey;
        this.$set(v, "selectedCount", setResult[1]);
        nodeKey = setResult[0];
        selectedCount += setResult[1];
      });
      return [nodeKey, selectedCount];
    },
    handleParentChecked(node) {
      this.initStateTree();

      let nodePath = [];
      this.stateTree.some(value => {
        if (value.nodeKey === node.parentKey) {
          nodePath.push(value);
          return true;
        }
        if (this.getNodePath(value, node.parentKey, nodePath)) {
          nodePath.push(value);
          return true;
        }
      });
      if (nodePath.length > 0) {
        nodePath.forEach(v => {
          this.$set(v, "checked", v.selectedCount > 0);
        });
      }
    },
    getNodePath(data, nodeKey, nodePath) {
      return this.getChildren(data).some(value => {
        if (value.nodeKey === nodeKey) {
          nodePath.push(value);
          return true;
        }

        if (this.getNodePath(value, nodeKey, nodePath)) {
          nodePath.push(value);
          return true;
        }
      });
    },
    getChildren(data) {
      if (Object.prototype.hasOwnProperty.call(data, this.childrenKey)) {
        return data[this.childrenKey];
      } else {
        return [];
      }
    }
  },
  mounted() {
    this.$on("on-check", this.handleParentChecked);
  }
};
</script>
